// eslint-disable-next-line no-redeclare,no-unused-vars
function arrayValues(array, sub, restrict) {
  var values = [];
  if (!array) return values;
  else if (restrict)
    $.each(restrict, function (i, key) {
      if (array[key]) values.push(array[key][sub]);
    });
  else
    $.each(array, function (i, val) {
      values.push(val[sub]);
    });
  return values;
}
